import React, { useEffect, useMemo, useState } from 'react';
import {
    Amount,
    AmountInput,
    Button,
    Cell,
    Flex,
    FlexColumns,
    Sidepanel,
    Tag,
    Textarea,
    Typography,
} from '@alphakits/ui/dist';
import { BaseForm } from '@alphakits/ui/dist/form/templates/base-form';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { CertificateSaleRO } from '@escapenavigator/types/dist/certificate-sale/certificate-sale.ro';
import { CreateCertificatesaleDiscountDto } from '@escapenavigator/types/dist/certificate-sale/create-certificatesale-discount.dto';
import { ProfileCurrencyEnum } from '@escapenavigator/types/dist/profile/enum/profile-currency';
import { PromocodeTypeEnum } from '@escapenavigator/types/dist/promocode/emun/promocode-type.enum';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { validatePromocode } from '@escapenavigator/utils/dist/validate-promocode';
import { TFunction } from 'i18next';
import { useApi } from 'src/providers/api/context';

type Props = {
    t: TFunction;
    close: () => void;
    setCerificate: (values: CertificateSaleRO) => void;
    certificatesale: CertificateSaleRO;
    currency: ProfileCurrencyEnum;
    amount?: number;
};

export const AddHandDiscount: React.FC<Props> = ({
    close,
    t,
    certificatesale,
    setCerificate,
    currency,
    amount = 0,
}) => {
    const { certificatesaleDiscounts, promocodes, certificatesales } = useApi();

    // const {
    //     queryFetch: queryPromocodes,
    //     queryLoading: loadingPromocodes,
    //     queryError: errorPromocodes,
    //     queryData: promocodesData,
    // } = useApiMethod({
    //     api: promocodes.query,
    // });

    const { queryFetch: queryPresavePromocodes, queryData: presavePromocodesData } = useApiMethod({
        api: promocodes.query,
    });

    useEffect(() => {
        queryPresavePromocodes({
            page: 1,
            limit: 20,
            sort: 'code',
            where: {
                multiple: true,
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const createPromocodeApi = useApiMethod({
        api: certificatesales.createPromocode,
        successCallback: ({ data }) => {
            close();
            setCerificate(data);
        },
    });

    const { createFetch, createLoading, createError } = useApiMethod({
        api: certificatesaleDiscounts.create,
        successCallback: ({ data }) => {
            setCerificate(data);
            close();
        },
    });

    const multiple = useMemo(() => {
        if (!presavePromocodesData) return [];

        return presavePromocodesData?.items.filter((p) => {
            const { valid } = validatePromocode({
                otherPromocodes: certificatesale.promocodes,
                promocode: p,
                type: 'certificate',
                isInside: true,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            });

            return valid;
        });
    }, [presavePromocodesData, certificatesale]);

    const [type, setType] = useState<'promocode' | 'discount'>('promocode');

    return (
        <Sidepanel title={ t('Скидка') } loading={ createLoading }>
            <FlexColumns columns={ 1 } gr={ 32 } gc={ 0 }>
                <Flex justify="start" gap="sm">
                    <Tag
                        onClick={ () => setType('promocode') }
                        text={ t('Промокод') }
                        view={ type === 'promocode' ? 'primary-inverted' : 'primary' }
                    />

                    <Tag
                        onClick={ () => setType('discount') }
                        text={ t('Ручная скидка') }
                        view={ type === 'discount' ? 'primary-inverted' : 'primary' }
                    />
                </Flex>

                { type === 'discount' ? (
                    <BaseForm
                        t={ t }
                        initialValues={ serializeRecord(CreateCertificatesaleDiscountDto, {
                            certificatesaleId: certificatesale.id,
                            amount,
                        }) }
                        enableReinitialize={ true }
                        validate={ validateByDto(t) }
                        loading={ false }
                        save={ async (data) => {
                            createFetch({ data });
                        } }
                    >
                        { ({
                            values, handleChange, errors, touched, setFieldValue, submitForm,
                        }) => (
                            <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                                <AmountInput
                                    value={ values.amount }
                                    suffix={ currency }
                                    error={ touched.amount && errors.amount }
                                    onChange={ (e, { value }) => setFieldValue('amount', value) }
                                    label={ t('Укажите значение ручной скидки') }
                                    required={ true }
                                    block={ true }
                                    type="decimal"
                                />

                                <Textarea
                                    value={ values.reason }
                                    maxLength={ 100 }
                                    counter={ true }
                                    required={ true }
                                    error={ touched.reason && errors.reason }
                                    onChange={ handleChange('reason') }
                                    label={ t('Причина') }
                                    block={ true }
                                />

                                { createError }

                                <Button view="primary" onClick={ submitForm }>
                                    { t('save') }
                                </Button>
                            </FlexColumns>
                        ) }
                    </BaseForm>
                ) : (
                    <FlexColumns columns={ 1 } gr={ 12 }>
                        { !!multiple?.length && (
                            <React.Fragment>
                                <Typography.Title tag="div" view="xxsmall" weight="bold">
                                    { t('Многоразовые промокоды') }
                                </Typography.Title>

                                { multiple.map((p) => (
                                    <Flex>
                                        <Cell.Base
                                            subtitle={ (
                                                <React.Fragment>
                                                    { t('Скидка') }{ ' ' }
                                                    <Amount
                                                        value={ p.discount }
                                                        type="decimal"
                                                        currencyOpacity={ false }
                                                        currency={
                                                            p.type === PromocodeTypeEnum.FIXED
                                                                ? currency
                                                                : '%'
                                                        }
                                                    />
                                                </React.Fragment>
                                            ) }
                                            title={ p.code }
                                        />
                                        <Button
                                            size="xs"
                                            disabled={ false }
                                            view="outlined"
                                            onClick={ () =>
                                                createPromocodeApi.createPromocodeFetch({
                                                    data: {
                                                        certificateId: certificatesale.id,
                                                        code: p.code,
                                                    },
                                                }) }
                                        >
                                            { t('Применить') }
                                        </Button>
                                    </Flex>
                                )) }
                            </React.Fragment>
                        ) }
                    </FlexColumns>
                ) }
            </FlexColumns>
        </Sidepanel>
    );
};
