import { TransactionTypeEnum } from '@escapenavigator/types/dist/transaction/enum/transaction-type.enum';

export const isTransactionCompiete = (type: TransactionTypeEnum) =>
    type === TransactionTypeEnum.INSIDE ||
    type === TransactionTypeEnum.SUCCEEDED ||
    type === TransactionTypeEnum.PROCESSING;

export const getTransactionStatusText = (type: TransactionTypeEnum, deleted: boolean, t) => {
    if (deleted) {
        return `(${t('Удалено')})`;
    }
    switch (type) {
        case TransactionTypeEnum.PROCESSING:
            return `(${t('Обрабатывается')})`;
        case TransactionTypeEnum.CANCELED:
            return `(${t('Отклонен')})`;
        case TransactionTypeEnum.CREATED:
            return `(${t('Не завершен')})`;

        default:
            return '';
    }
};

export const getTransactionStatusColor = (amount: number, deleted: boolean) => {
    if (deleted) {
        return 'secondary';
    }

    return amount > 0 ? 'positive' : 'negative';
};
