/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
import { useEffect, useState } from 'react';
import { showError } from '@alphakits/ui/dist';
import { CertificateSaleRO } from '@escapenavigator/types/dist/certificate-sale/certificate-sale.ro';
import { getServiceError } from '@escapenavigator/utils/dist/get-service-error';
import { AxiosPromise } from 'axios';
import { useApi } from 'src/providers/api/context';

export const useCertificatesApi = (id: number, successCalback?: () => void) => {
    const [certificatesale, setCertificateSale] = useState<CertificateSaleRO>(
        {} as CertificateSaleRO,
    );
    const [loading, setLoading] = useState(false);
    const [updating, setUpdating] = useState(false);

    const { certificatesales, certificatesaleDiscounts } = useApi();

    const getCertificate = async () => {
        setLoading(true);
        try {
            const { data } = await certificatesales.getOne(id);

            setCertificateSale(data);
        } catch (err) {
            alert('error');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getCertificate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const asyncRequest = (promise: AxiosPromise<CertificateSaleRO>) => {
        // eslint-disable-next-line no-restricted-globals

        setUpdating(true);

        return promise
            .then(({ data }) => {
                const orderData = data;

                setCertificateSale(orderData);

                setUpdating(false);

                if (successCalback) successCalback();

                return orderData;
            })
            .catch((err) => {
                showError(getServiceError(err).message);
                setUpdating(false);
            });
    };

    const removeHandDiscount = (discountID: number) =>
        confirm('are you sure?') && asyncRequest(certificatesaleDiscounts.remove(discountID));

    const removePromocode = (id: number) =>
        confirm('are you sure?') && asyncRequest(certificatesales.removePromocode(id));

    const annul = (recordId: number) =>
        confirm('are you sure?') && asyncRequest(certificatesales.annul(recordId));

    const ship = () => asyncRequest(certificatesales.ship({ id: certificatesale.id }));

    return {
        loading: updating || loading,
        removeHandDiscount,
        removePromocode,
        annul,
        updating,
        certificatesale,
        setCertificateSale,
        ship,
    };
};
