import React from 'react';
import { CertificateSaleRO } from '@escapenavigator/types/dist/certificate-sale/certificate-sale.ro';
import { OrderRO } from '@escapenavigator/types/dist/order/order.ro';
import { TransactionTypeEnum } from '@escapenavigator/types/dist/transaction/enum/transaction-type.enum';

import { Transaction } from './transaction';

type OrderProps = {
    type: 'order';
    setData: (data: OrderRO) => void;
    transactions: OrderRO['transactions'];
};

type CertificateProps = {
    type: 'certificate';
    setData: (data: CertificateSaleRO) => void;
    transactions: CertificateSaleRO['transactions'];
};

export const TransactionsList: React.FC<OrderProps | CertificateProps> = ({
    transactions = [],
    setData,
    type,
}) => {
    if (!transactions.length) return null;

    return (
        <React.Fragment>
            { transactions
                .filter(
                    (tr) =>
                        tr.type === TransactionTypeEnum.INSIDE ||
                        tr.type === TransactionTypeEnum.PROCESSING ||
                        tr.type === TransactionTypeEnum.SUCCEEDED,
                )
                .map((transaction) => (
                    <Transaction
                        setData={ setData }
                        type={ type }
                        key={ +transaction.createdAt }
                        transaction={ transaction }
                    />
                )) }
        </React.Fragment>
    );
};
